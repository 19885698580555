import React from 'react';
import './styles/app.css';
import Home from "./views/home/Home";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Age from "./views/age/Age";

const HomeWide = () =>
    <div className="w-full h-full">
        <Home/>
    </div>
function App() {
    return (
        <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route exact path="/" component={HomeWide}/>
                /*<Route path="/age" component={Age}/>*/
            </Switch>
        </Router>
    );
}

export default App;
