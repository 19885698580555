import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";

const content = {
    fName: "~/Abdullah",
    lName: "Alqarni",
    desc: "Software Engineer",
    urls: {
        github: "https://github.com/elgarni",
        linkedin: "https://linkedin.com/in/iaalqarni"
    },
    interestStmt: "DevOps, SRE, CI/CD & Automation Pipelines, Public Clouds",
};

const filter = {
    filter: `invert(93%) sepia(20%) saturate(3778%) hue-rotate(318deg) brightness(99%) contrast(95%)`
}

export const Home = () =>

    <div className="bg-gray-900 w-full h-screen grid grid-rows-3 grid-cols-1 lg:grid-cols-3  xl:grid-cols-3">
        <div className="row-start-2 col-start-2">
            <div className="text-center font-mont text-orange-400 grid grid-rows-4 grid-cols-1 ">
                <p className="text-3xl  md:text-6xl xl:text-5xl lg:text-6xl">{content.fName} <span
                    className="font-hairline">{content.lName}</span></p>
                <p className="text-2xl sm:text-xl md:text-3xl xl:text-3xl lg:text-3xl font-hairline ">
                    Software <span className="font-normal">Engineer</span>
                </p>
                <div className="social-icons grid grid-rows-1 grid-flow-col m-auto gap-6">
                    {
                        Object.keys(content.urls).map((url) =>
                            <a href={content.urls[url]} target="_blank">
                                <img style={filter} className="img-filter h-16 "
                                     src={`https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/${url}.svg`}/>
                            </a>
                        )
                    }
                </div>
                <div className="m-4 font-light">
                    <p>
                        {content.interestStmt}
                    </p>
                </div>
            </div>
        </div>
    </div>


export default Home;
