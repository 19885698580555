import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

const content = {
    fName: "~/Abdullah",
    lName: "Alqarni",
    desc: "Software Engineer",
    urls: {
        github: "https://github.com/elgarni",
        linkedin: "https://linkedin.com/in/iaalqarni"
    },
    interestStmt: "DevOps, SRE, CI/CD & Automation Pipelines, Public Clouds",
};

const filter = {
    filter: `invert(93%) sepia(20%) saturate(3778%) hue-rotate(318deg) brightness(99%) contrast(95%)`
}

const birthday = new Date(1996, 1, 8, 12, 0, 0, 0)
const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
const oneHour =  60 * 60 * 1000; // minutes*seconds*milliseconds
const oneMinute =  60 * 1000; // seconds*milliseconds
const oneSecond =   1000; // 1 * *milliseconds


export const Age = () => {
    const [date, setDate] = useState(Date.now());
    console.log(date)
    const diffTime = Math.abs(Date.now() - birthday);
    const diffDate = new Date(diffTime);
    const years = diffDate.getUTCFullYear() % 1970;
    const months = diffDate.getUTCMonth();
    const days = Math.floor((diffTime / oneDay) % 30) ;
    const hours = Math.floor((diffTime / oneHour) % 24) ;
    const minutes = Math.floor( (diffTime / oneMinute)  % 60 ) ;
    const seconds = Math.floor( (diffTime / oneSecond)  % 60) ;

    const ageNumber = `<span>${years}years <br/>    ${months}M ${days}D ${hours}h ${minutes}M ${seconds}s </span>`

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(old => Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    return <div className="bg-gray-900 w-full h-screen grid grid-rows-3 grid-cols-1 lg:grid-cols-3  md:grid-cols-1 xl:grid-cols-3">
        <div className="row-start-2 col-start-1 lg:col-start-2 ">
            <div className="grid grid-cols-2 w-full h-full  text-2xl sm:text-xl md:text-5xl xl:text-5xl lg:text-5xl font-hairline text-orange-400 text-center m-0 p-0 gap-0">
                <p className="text-right m-1 font-bold">{years}</p>
                <p className="text-left m-1 ">Years</p>

                <p className="text-right m-1 font-bold">{months}</p>
                <p className="text-left m-1">Months</p>

                <p className="text-right m-1 font-bold">{days}</p>
                <p className="text-left m-1">Days</p>

                <p className="text-right m-1 font-bold">{hours}</p>
                <p className="text-left m-1">Hours</p>

                <p className="text-right m-1 font-bold">{minutes}</p>
                <p className="text-left m-1">Minutes</p>

                <p className="text-right m-1 font-bold">{seconds}</p>
                <p className="text-left m-1">Seconds</p>


            </div>

        </div>
    </div>
}




export default Age;